/* .App {
  text-align: center;
} */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,700;1,6..12,700&family=Nunito:ital@0;1&display=swap');
:root {
  --main-color: #1a4a22;
}

body {
  font-family: 'Nunito Sans', sans-serif;
}

/* Splash Screen */
.splash-logo {
  width: 300px;
}

@media (max-width: 767px) {
  .splash-logo {
    width: 200px;
  }
}

/* login */
.form-check-input {
  border: 1px solid black !important;
  border-radius: 0px !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--main-color) !important;
}

.text-black {
  color: black;
}

.text-color {
  color: var(--main-color) !important;
}

.bg-button {
  background-color: var(--main-color) !important;
}

.bg-green {
  background: #1a4a22;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.text-muted {
  color: #999999 !important;
}

.bottom-line {
  bottom: 15px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--main-color) !important;
}

.password {
  top: 50px;
  left: 20px;
}

.form-control {
  padding-left: 60px !important;
}

.card-shadow {
  border: 1px solid #e1e1e1 !important;
}

.row-100vh {
  display: flex !important;
  flex-direction: column !important;
  height: 100vh !important;
}

.top,
.bottom {
  flex: 0 0 auto !important;
}

.middle {
  flex: 1 !important;
  overflow-y: auto !important;
}

.nav-pills .nav-link.active {
  background-color: var(--main-color) !important;
  border-radius: 100px;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
}

.nav-pills .nav-link {
  background-color: #d9d9d9 !important;
  border-radius: 100px !important;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.nav-pills .nav-link:hover {
  color: black;
}

@media (max-width: 767px) {
  .navbar.navbar-expand-lg.navbar-dark.bg-button {
    background: none !important;
    margin-top: -58px;
  }

  .navbar.navbar-expand-lg.navbar-dark.bg-button.new {
    background: none !important;
    margin-top: -75px;
  }

  .navbar-toggler {
    padding: 0px !important;
  }

  .navbar-toggler-icon {
    height: 40px !important;
    width: 40px !important;
    background-image: url("data:image/svg+xml,%3Csvg style='color: rgb(0, 0, 0);' height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m4.5 6.5h12' fill='%23000000'%3E%3C/path%3E%3Cpath d='m4.498 10.5h11.997' fill='%23000000'%3E%3C/path%3E%3Cpath d='m4.5 14.5h11.995' fill='%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") !important;
  }

  .offcanvas.offcanvas-start {
    background: var(--main-color) !important;
  }

  .dropdown-toggle.gg::after {
    float: right;
    margin-left: auto !important;
  }

  .image-width {
    width: 24px;
  }

  .offcanvas.offcanvas-start {
    width: 300px !important;
  }

  .nav-pills .nav-link {
    font-size: 15px !important;
    padding: 10px !important;
  }
}

.bg-none {
  background: transparent !important;
}

.text-grey {
  color: #a4a4a4;
}

.bg-button-reset {
  background: #d4d4d4 !important;
  color: black;
}

.text-777 {
  color: #777;
}

.offcanvas.offcanvas-bottom {
  height: 320px !important;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.form-select:focus {
  box-shadow: none !important;
  border-color: var(--main-color) !important;
}

.bg-green-completed {
  background: #83c326 !important;
}

.subject {
  padding-left: 16px !important;
}

.notification-child li:nth-child(even) {
  background: #b0ccb5;
}

.notification-child li::before {
  content: '';
  margin-right: 10px;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
  background: black;
  max-width: 10px;
  display: inline-block;
}

.notification-child li {
  padding: 20px;
}

.active {
  color: #83c326;
}
.pointer_setting {
  cursor: pointer;
}
.error {
  color: red;
}
.loader {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff3d;
}
.dowload-icon {
  background: #83c326 !important;
  padding-top: 12px;
  padding-bottom: 12px;
  color: white !important;
  font-weight: 700;
  font-size: 18px;
  border-radius: 10px;
  border-color: #83c326 !important;
}

.mr-8 {
  margin-right: 8px;
}

.zones-btn {
  border-radius: 6px;
  background: #1a4a22;
  border: none;
  height: 75%;
  width: 40%;
  color: #fff;
  font-size: larger;
}
.add-zone-btn {
  width: 20%;
  margin-left: 16px;
}

.bw-filter {
  filter: invert(50%) sepia(100%) saturate(0%);
}
/* .stack-barchart{
    padding: 10px;
    width: 40%;
} */

.stackedbarchart {
  height: 500px;
}
.edit-logo {
  width: 50px;
}

.sign {
  width: 120px;
  height: 82px;
}

.px-104 {
  padding-left: 104px;
  padding-right: 25px;
}

.report-subdetails {
  max-height: 500px;
  overflow-y: scroll;
  padding-left: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media only screen and (min-width: 1026px) {
  .ml-80 {
    margin-left: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .px-104 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.filter-btn {
  border: none;
  color: #fff;
  background: #1a4a22;
  border-radius: 3px;
  padding: 14px;
  font-size: large;
}

#dropdown-variants-primary {
  border: none;
  background: #1a4a22;
  padding: 14px;
  font-size: large;
}

.date-range-picker {
  margin-left: 20px;
  margin-right: 30px;
}

.view-media-btn {
  background-color: #18431feb;
  border: none;
  color: #fff;
  width: 120px !important;
  border-radius: 4px;
  padding: 4px;
}

.cara-image-container {
  text-align: center;
}

.cara-report-image {
  max-height: 80vh;
  margin: auto;
}

.carousel-control-prev-icon {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: 60%;
}

.carousel-control-next-icon {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: 60%;
}

.view-doc-anchor {
  display: block;
  color: #2a5231; 
}

.view-doc-anchor:hover {
  transition: 0.4s;
  color: #379749; 
}